<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">Daftar <b>User</b></div>
      <b-button
        squared
        variant="primary"
        @click="$router.push('/masters/users/add')"
        v-if="btnAccess"
        >Tambah</b-button
      >
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <b-tabs content-class="mt-3">
              <b-tab title="Dosen" active @click="getUserByRole(1)">
              </b-tab>
              <div v-for="role in roles" :key="role.id">
              <b-tab :title="role.name" @click="getUserByRole(role.id)">
              </b-tab>
              </div>
            </b-tabs>
            <Table purpose="master" route="api/users" :roleId="filter.role_id" :filterParticipant="filterParticipant" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import Table from "@/view/components/users/Table.vue";

export default {
  name: "master-users",
  components: {
    Table,
  },
  data() {
    return {
      // to fix error
      filterParticipant:{
        role_id: 0
      },
      // filter
      filter: {
        name: "",
        role_id: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "photo",
          label: "Foto",
        },
        {
          key: "role_name",
          label: "Peran",
          sortable: true,
        },
        {
          key: "grade_name",
          label: "Tingkat",
          sortable: true,
        },
        {
          key: "phone",
          label: "Telepon",
          sortable: true,
        },
        {
          key: "school_name",
          label: "Sekolah",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
        },
      ],
      items: [],
      roles: [],
      role: "",
      // access
      btnAccess: false
    };
  },
  methods: {
    getUserByRole(role_id = 1) {
      this.$root.$emit("getUserByRole", role_id);
    },
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}`;
      let response = await module.paginate(
        "api/users",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    async getRoles() {
      let response = await module.paginate("api/roles");
      for(let a = 0; a < response.data.length; a++){
        if(response.data[a].id != 1){
          this.roles.push(response.data[a])
        }
      }
      this.getUserByRole()
    },

    filterByName() {
      this.pagination();
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("api/users/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "9002") {
          this.btnAccess = true
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Master", route: "/masters/settings" },
      { title: "User" },
    ]);
    // this.pagination();
    this.getRoles();
    this.checkAccess();
    this.filter.role_id = this.$route.params.id
  },
  beforeRouteUpdate(to, from, next) {
    // this.getContent(to.params.uid);
    this.filter.role_id = to.params.id;
    //("ketekan")
    next();
  },
};
</script>
